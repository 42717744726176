import * as React from 'react';
import {useTabs, TabPanel} from "react-headless-tabs"
import {TabSelector} from './TabSelector';

import {
    container,
    tabContent,
    splitContent,
    text,
    grid,
    grid__column,
    media
} from "../../scss-modules/tabs.module.scss";


export function AlternativeEnergyTabs() {
    const [selectedTab, setSelectedTab] = useTabs([
        'carbonCapture',
        'hydrogenPower',
        'batteryStorage'
    ]);

    return (
        <div className={`container large-container ${container}`}>
            <aside>
                <nav className="sidebar-nav">
                    <ul className="sidebar-list">
                        <TabSelector isActive={selectedTab === 'carbonCapture'}
                                     onClick={() => setSelectedTab('carbonCapture')}>
                            Carbon Capture
                        </TabSelector>
                        <TabSelector isActive={selectedTab === 'hydrogenPower'}
                                     onClick={() => setSelectedTab('hydrogenPower')}>
                            Hydrogen Power
                        </TabSelector>
                        <TabSelector isActive={selectedTab === 'batteryStorage'}
                                     onClick={() => setSelectedTab('batteryStorage')}>
                            Battery Storage
                        </TabSelector>
                    </ul>
                </nav>
            </aside>


            {/*Content*/}
            <div className={`container`} style={{width: '100%'}}>
                <div className="content">
                    <TabPanel className={tabContent} hidden={selectedTab !== 'carbonCapture'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Carbon Capture, Storage & Transportation</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Pre and post combustion</li>
                                            <li>Solvent based CO2 capture</li>
                                            <li>Sorbent based CO2 capture</li>
                                            <li>Membrane based CO2 capture</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Transport</li>
                                            <li>Storage</li>
                                            <li>Commercial Management</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../alternative-energy/alternative-carbon-capture.jpg'}
                                     alt="Carbon Capture"/>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel className={tabContent} hidden={selectedTab !== 'hydrogenPower'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Hydrogen Power</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Production</li>
                                            <li>Transformation</li>
                                            <li>Transportation</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>End Use supply</li>
                                            <li>Electrolysis</li>
                                            <li>Fuel cells and storage</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../alternative-energy/alternative-hydrogen.jpg'} alt="Hydrogen Power"/>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel className={tabContent} hidden={selectedTab !== 'batteryStorage'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Battery Storage</h3>
                                <ul className="formatted-list">
                                    <li>Static Storage</li>
                                    <li>Frequency Response</li>
                                    <li>EV</li>
                                </ul>
                            </div>
                            <div className={media}>
                                <img src={'../../alternative-energy/alternative-battery-storage.jpg'}
                                     alt="Battery Storage"/>
                            </div>
                        </div>
                    </TabPanel>


                </div>
            </div>
        </div>
    );
}