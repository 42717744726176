import * as React from 'react';

export const TabSelector = ({
                                isActive,
                                children,
                                onClick,
                            }: {
    isActive: boolean;
    children: React.ReactNode;
    onClick: () => void;
}) => (
    <>
        <li
            className={`${isActive ? 'nav-item active' : 'nav-item'}`}
            onClick={onClick}
        >

            {children}

        </li>
    </>
);