import * as React from "react";
import "../scss/global.scss";
import {fullWidthCta, fullWidthCta__caption} from "../scss-modules/full-width-cta.module.scss";

//RENDER
export default function FullWidthCTA({id, ctaBackgroundImg, children}) {
    return (
        <section id={id}>
            <div className={fullWidthCta} style={{backgroundImage: `url(${ctaBackgroundImg})`}}>
                <div className="container large-container">
                    <div className={fullWidthCta__caption}>
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}
